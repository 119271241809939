(function () {

    /*
     * 日期格式化
     * from http://www.cnblogs.com/zhangpengshou/archive/2012/07/19/2599053.html
     */
    Date.prototype.format = function (fmt) {
        var o = {
            'M+': this.getMonth() + 1, //月份
            'd+': this.getDate(), //日
            'h+': this.getHours(), //小时
            'm+': this.getMinutes(), //分
            's+': this.getSeconds(), //秒
            'q+': Math.floor((this.getMonth() + 3) / 3), //季度
            'S': this.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp('(' + k + ')').test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ?
                    (o[k]) :
                    (('00' + o[k]).substr(('' + o[k]).length)));
        return fmt;
    };

    // https://stackoverflow.com/questions/19491336/get-url-parameter-jquery-or-how-to-get-query-string-values-in-js
    // 修复URL中有参数是URL, 取出时少参数的BUG
    function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    }
    // https://blog.csdn.net/zerogf/article/details/79105567
    // 生成随机数
    function random(lower, upper) {
        return Math.floor(Math.random() * (upper - lower + 1)) + lower;
    }
    window.getUrlParameter = getUrlParameter;
    window.random = random;
})();
var icon = require('../images/icon.png')
$(function () {
    var URLS = {
        'home': '../home/index.html',
        'business': '../business/index.html',
        'recruit': '../recruit/index.html',
        'about': '../about/index.html',
        'gyouliao': '../gyouliao/index.html'
    }
    $('body').delegate('.head-tab-cell', 'click', function () {
        console.log(1111)
        if ($(this).hasClass('active')) {
            return;
        }
        window.location.href = URLS[$(this).attr('data-key')];
    });
})